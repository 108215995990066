import type { ConfigType } from '@markdoc/markdoc';
import React, { useEffect, useState } from 'react';

import useWindowSize from '../../utils/useWindowSize';
import { useFormState } from './useFormState';
import { VimeoPlayer } from './VimeoPlayer';

const config: ConfigType = {
  tags: {
    video: {
      render: 'VideoPlayer',
      children: [],
      attributes: {
        video: {
          type: String,
        },
        src: {
          type: String,
        },
        audio: {
          type: String,
        },
      },
      selfClosing: true,
    },
    media: {
      render: 'VideoPlayer',
      children: [],
      attributes: {
        video: {
          type: String,
        },
        src: {
          type: String,
        },
        audio: {
          type: String,
        },
      },
      selfClosing: true,
    },
  },
};

interface IProps {
  video?: string;
  src: string;
  audio?: string;
}

const VideoPlayer = ({ video, src, audio }: IProps) => {
  const { isMobile, width } = useWindowSize();

  const [segmentsWatched, setSegmentsWatched] = useState(
    new Array(100).fill(0)
  );
  const { setComponentState } = useFormState('videoPlayer', src, '');

  const handleChange = () => {
    setComponentState(segmentsWatched);
  };

  useEffect(() => {
    handleChange();
  }, [segmentsWatched]);

  const handleTimeUpdate = (e: Event) => {
    const videoElement = e.target as HTMLVideoElement;
    const index = Math.floor(
      (videoElement.currentTime / videoElement.duration) * 100
    );
    const newSegments = [...segmentsWatched];
    if (newSegments[index] === 0) {
      // Increment the segment count only the first time it's played
      newSegments[index] = 1;
      setSegmentsWatched(newSegments);
    }
  };

  const handleVideoEnd = () => {
    // Send data to backend
    // console.log('Video ended', segmentsWatched);
    // Replace console.log with a function to send this data to your backend
  };

  useEffect(() => {
    const videoElement = document.getElementById('videoPlayer');
    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('ended', handleVideoEnd);

      return () => {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        videoElement.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, [segmentsWatched]);
  if (video) {
    return <VimeoPlayer video={video} />;
  }
  if (audio) {
    return (
      <audio
        src={audio}
        className="mx-auto w-max"
        style={{ width: isMobile ? `${(width ?? 200) - 100}px` : '450px' }}
        controls
      />
    );
  }
  return (
    <div>
      {isMobile ? (
        <video
          id={'videoPlayer'}
          src={src}
          className="mx-auto w-max rounded"
          width={350}
          style={{ minHeight: '150px' }}
          controls
        />
      ) : (
        <video
          id={'videoPlayer'}
          src={src}
          className="mx-auto rounded-lg"
          width={width}
          style={{ minHeight: '360px' }}
          controls
        />
      )}
    </div>
  );
};

export { VideoPlayer, config as videoPlayerConfig };
