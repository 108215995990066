import { jwtControl } from '../graphql/apollo';
import { useVerifyMobileMutation } from '../graphql/generated-types';

interface IArgs {
  id: string;
  tokenKey: string;
}
export const useVerifyMobile = () => {
  const [verifyMobile, { loading, error }] = useVerifyMobileMutation();

  const verify = async (args: IArgs) => {
    const { data } = await verifyMobile({
      variables: {
        id: args.id,
        key: args.tokenKey,
      },
    });

    if (
      data &&
      data.verifyMobile &&
      data.verifyMobile.userProfile &&
      data.verifyMobile.userProfile.mobile
    ) {
      jwtControl.setMobileVerified(true);
      return data.verifyMobile.userProfile.mobile;
    }
    return false;
  };

  return {
    verify,
    loading,
    error,
  };
};
